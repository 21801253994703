import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'

const ContactThankYou = () => (
  <Layout>
    <SEO title="Thank You for Contacting Us" />
    <Container>
      <h1>Thank You</h1>
      <p>Thank you for contacting us! We'll be in touch shortly.</p>
    </Container>
  </Layout>
)

export default ContactThankYou
